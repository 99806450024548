<template>
  <BCard
    header-class="py-50 text-airline px-1"
    body-class="pb-0"
  >
    <template #header>
      <div :class="`fw-700 text-nowrap ${isMobileView ? 'font-medium-1' : 'font-medium-2'}`">
        {{ $t(`flight.agencyInfo`) }}
      </div>
      <BButton
        variant="flat-dark"
        class="p-50 text-nowrap"
        @click="$emit('toggleCollapse')"
      >
        <feather-icon
          v-if="!isCollapsed"
          icon="ChevronDownIcon"
          size="17"
        />
        <feather-icon
          v-else
          icon="ChevronUpIcon"
          size="17"
        />
      </BButton>
    </template>
    <BCollapse :visible="!isCollapsed">
      <IAmOverlay :loading="!agencyData">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Phone"
              rules="required|integer|phoneNumber"
              mode="eager"
            >
              <b-form-group
                label-for="phone-agency"
              >
                <template #label>
                  <div class="">
                    {{ $t('flight.phoneNumber') }}
                    <span class="text-danger">(*)</span>
                  </div>
                </template>
                <b-form-input
                  id="phone-agency"
                  v-model="agencyData.phoneNumber"
                  :state="getValidationState(validationContext) === false ? false : null"
                  lazy-formatter
                  type="number"
                  :disabled="checkboxCustomerDataReplaceAgencyData || !isRoleF1"
                  :formatter="trimInput"
                  :placeholder="$t('flight.placeholderInput')"
                />
                <b-tooltip
                  v-if="validationContext.errors[0]"
                  target="phone-agency"
                  triggers="hover focus"
                  placement="top"
                  boundary="viewport"
                  variant="danger"
                  :title="validationContext.errors[0]"
                >
                  <span class="text-white py-25 mb-0">
                    {{ validationContext.errors[0] }}
                  </span>
                </b-tooltip>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="email|max:120"
              mode="eager"
            >
              <b-form-group
                label-for="email-agency"
              >
                <template #label>
                  <div class="">
                    {{ $t('flight.email') }}
                    <span class="text-danger">(*)</span>
                  </div>
                </template>
                <b-form-input
                  id="email-agency"
                  v-model="agencyData.emailAddress"
                  :state="
                    getValidationState(validationContext) === false ? false : null
                  "
                  maxlength="120"
                  lazy-formatter
                  :disabled="checkboxCustomerDataReplaceAgencyData || !isRoleF1"
                  :formatter="trimInput"
                  :placeholder="$t('flight.placeholderInput')"
                />

                <b-tooltip
                  v-if="validationContext.errors[0]"
                  target="email-agency"
                  triggers="hover focus"
                  placement="top"
                  boundary="viewport"
                  variant="danger"
                  :title="validationContext.errors[0]"
                >
                  <span class="text-white py-25 mb-0">
                    {{ validationContext.errors[0] }}
                  </span>
                </b-tooltip>

              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-form-checkbox
          v-if="enableSendEmployeeMailAirline"
          :checked="isChecked"
          class="mb-50"
          @input="inputCheckboxHandle"
        >
          <span>{{ $t('flight.checkboxSendEmployeeMailAirline') }}</span>
        </b-form-checkbox>
      </IAmOverlay>
    </BCollapse>
  </BCard>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BTooltip,
  BFormCheckbox,
  BCard, BButton,
  BCollapse,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'

import IAmOverlay from '@/components/IAmOverlay.vue'

import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput } from '@core/comp-functions/forms/formatter-input'

import {
  required, min, max, email, phoneNumber,
} from '@validations'

export default {
  components: {
    BCard,
    BCollapse,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    IAmOverlay,
    BTooltip,
    BFormCheckbox,
    BButton,
    // Form Validation
    ValidationProvider,
  },

  props: {
    agencyData: {
      type: Object,
      default: () => {},
    },
    checkboxCustomerDataReplaceAgencyData: {
      type: Boolean,
      default: false,
    },
    isRoleF1: {
      type: Boolean,
      default: false,
    },
    enableSendEmployeeMailAirline: {
      type: Boolean,
      default: false,
    },
    checkSendEmployeeMailAirline: {
      type: Boolean,
      default: false,
    },
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { getValidationState } = formValidation()
    const isChecked = ref(props.checkSendEmployeeMailAirline)

    function inputCheckboxHandle(val) {
      emit('update:checkSendEmployeeMailAirline', val)
    }
    return {
      getValidationState,

      // Validations
      required,
      min,
      max,
      email,
      phoneNumber,
      trimInput,
      inputCheckboxHandle,
      isChecked,
    }
  },
}
</script>
